const formValidation = ( ) => {
  // Example POST method implementation:
  async function postData(url = '', data = {}) {
    const formData = new FormData();

    for (const property in data) {
      const value = data[property] ? data[property] : '';
      formData.append(property, value);
    }

    // Default options are marked with *
    const response = await fetch(url, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer',
      body: formData,
    });

    return response.json();
  }

  /**
   * Error class to highlight any errors on the inputs.
   */
  class InputError {
    ERROR_MESSAGE_CLASS = 'contact__form-error';
    ERROR_PARENT_CLASS = 'has-error';
    INPUT_REQUIRED = 'input-required';
    EMAIL_INCORRECT = 'email-incorrect';

    constructor(htmlElement) {
      if (!htmlElement) {
        throw new Error('HTMLElement is not defined');
      }

      this.htmlElement = htmlElement;
    }

    /**
     * @param {*} messageType
     * @returns {string}
     */
    selectMessage(messageType) {
      switch(messageType) {
        case this.INPUT_REQUIRED:
          return 'Dit veld is verplicht';
        case this.EMAIL_INCORRECT:
          return 'Dit is geen geldig emailadres';
        default:
          throw new Error('messageType is not defined.');
      }
    }

    /**
     * Is het the error set.
     *
     * @returns {boolean}
     */
    hasError() {
      return !!this.htmlElement.parentElement.classList.contains(this.ERROR_PARENT_CLASS);
    }

    /**
     * Create a new htmlElement and add it to the HTML as a sibling of the input.
     *
     * @param {string} messageType
     * @returns {void}
     */
    add(messageType) {
      const message = this.selectMessage(messageType);

      const div = document.createElement('div');
      div.classList.add(this.ERROR_MESSAGE_CLASS);
      div.innerText = message;

      this.htmlElement.parentElement.classList.add(this.ERROR_PARENT_CLASS);
      this.htmlElement.parentElement.append(div);
    }

    /**
     * Remove the error.
     *
     * @returns {void}
     */
    remove() {
      if (!this.hasError()) {
        return;
      }

      this.htmlElement.parentElement.classList.remove(this.ERROR_PARENT_CLASS);
      this.htmlElement.parentElement.querySelector(`.${this.ERROR_MESSAGE_CLASS}`).remove();
    }
  }

  class FormInput {
    pristine = true;
    required = false;
    touched = false;
    isValid = false;
    pristineState = '';

    constructor(htmlElement) {
      if (!htmlElement) {
        throw new Error('HTMLElement is not defined');
      }

      const {value, required, type} = htmlElement;

      this.input = htmlElement;

      this.error = new InputError(htmlElement);
      this.required = required;
      this.type = type;

      if (!this.isEmpty(value)) {
        this.pristineState = value;
      }

      this.addFocusEvent();
      this.addInputEvent();
      this.addBlurEvent();
    }

  /**
   *
   * @param {string} str
   * @returns {boolean}
   */
    isEmpty(str) {
      return str.length === 0 || ! str.trim();
    }

    validateFocus() {
      if ( this.touched ) {
        return;
      }

      this.touched = true;
    }

    /**
     *
     * @param {string} value
     */
    validateInput(value) {
      this.validate(value);
    }

    /**
     * @param {string} value
     */
    validate(value) {
      this.isValid = false;

      // If the user has never touched the element and value has not changed.
      if (this.pristine && !this.touched) {
        return;
      }

      if (this.required) {
        if (this.isEmpty(value)) {
          this.showError(this.error.INPUT_REQUIRED);
          return;
        }

        this.removeError();
      }

      if (this.error.hasError()) {
        this.removeError();
      }

      this.isValid = true;
    }

    /**
     * Show the error for this input.
     *
     * @param {string} messageType
     */
    showError(messageType) {
      this.removeError();
      this.error.add(messageType);
    }

    /**
     * Remove the error for this input.
     */
    removeError() {
      this.error.remove();
    }

    addFocusEvent() {
      this.input.addEventListener('focus', () => this.validateFocus(), false);
    }

    addInputEvent() {
			this.input.addEventListener('input', (event) => this.validateInput(event.target.value, 'blur'), false);
    }

    addBlurEvent() {
			this.input.addEventListener('blur', (event) => this.validate(event.target.value, 'blur'), false);
    }
  }

  class EmailFormInput extends FormInput {
    constructor(htmlElement) {
      super(htmlElement);
    }

    validate(value) {
      this.isValid = false;

      const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      // If the user has never touched the element and value has not changed.
      if (this.pristine && !this.touched) {
        return;
      }

      if (this.required) {
        if (this.isEmpty(value)) {
          this.showError(this.error.INPUT_REQUIRED);
          return;
        }

        this.removeError();
      }

      if (!value.match(pattern)) {
        this.showError(this.error.EMAIL_INCORRECT);
        return;
      } else {
        this.removeError();
      }

      if (this.error.hasError()) {
        this.removeError();
      }

      this.isValid = true;
    }
  }

  class SubmitButton {
    constructor(htmlElement) {
      if (!htmlElement) {
        throw new Error('HTMLElement is not defined');
      }

      this.htmlElement = htmlElement;
    }

    addClickEvent(fn) {
      this.htmlElement.addEventListener('click', (event) => {
        event.preventDefault();

        this.submit(fn);
      }, false);

      this.htmlElement.addEventListener('touchstart', (event) => {
        event.preventDefault();

        this.submit(fn);
      }, false);
    }

    /**
     * @param {Function} fn
     */
    submit(fn) {
      fn.call();
    }
  }

  class Modal {
    constructor(formElement) {
      if (!formElement) {
        throw new Error('formElemnent is not defined.');
      }

      this.formElement = formElement;
      this.build();
    }

    template() {
      return ``;
    }

    build() {
      var div = document.createElement('div');
      div.innerHTML = this.template();

      this.element = div.firstChild;
    }

    show() {
      this.formElement.append(this.element);
    }

    hide() {
      this.element.remove();
    }
  }

  class LoaderModal extends Modal {
    constructor(formElement) {
      super(formElement)
    }

    template() {
      return `<div class="contact__modal contact__modal--loader">
          <div class="contact__modal-inner">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 135 140">
              <rect y="10" width="15" height="120" rx="6">
                <animate attributeName="height" begin="0.5s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite" />
                <animate attributeName="y" begin="0.5s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite" />
              </rect>
              <rect x="30" y="10" width="15" height="120" rx="6">
                <animate attributeName="height" begin="0.25s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite" />
                <animate attributeName="y" begin="0.25s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite" />
              </rect>
              <rect x="60" width="15" height="140" rx="6">
                <animate attributeName="height" begin="0s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite" />
                <animate attributeName="y" begin="0s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite" />
              </rect>
              <rect x="90" y="10" width="15" height="120" rx="6">
                <animate attributeName="height" begin="0.25s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite" />
                <animate attributeName="y" begin="0.25s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite" />
              </rect>
              <rect x="120" y="10" width="15" height="120" rx="6">
                <animate attributeName="height" begin="0.5s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite" />
                <animate attributeName="y" begin="0.5s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite" />
              </rect>
            </svg>

            <div>
              <p>Versturen...</p>
            </div>
          </div>
        </div>`;
    }
  }

  class SuccessModal extends Modal {
    constructor(formElement) {
      super(formElement);
    }

    template() {
      return `<div class="contact__modal contact__modal--success">
          <div class="contact__modal-inner">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path d="M20.285 2 9 13.567 3.714 8.556 0 12.272 9 21 24 5.715z" />
            </svg>

            <div>
              <p>Bedankt!</p>

              <p>Het bericht is verstuurd.</p>
            </div>
          </div>
        </div>`;
    }
  }

  class FailureModal extends Modal {
    constructor(formElement) {
      super(formElement);
    }

    template() {
      return `<div class="contact__modal contact__modal--failure">
          <div class="contact__modal-inner">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108.81 122.88">
              <path d="M62.72 69.75h37.12a9 9 0 0 1 9 9v35.15a9 9 0 0 1-9 9H62.72a9 9 0 0 1-9-9V78.72a9 9 0 0 1 9-9Zm21.74-41.56a3.15 3.15 0 0 1 2.29 3v5.29h7.85a6.82 6.82 0 0 1 4.88 2 6.91 6.91 0 0 1 2 4.88v16.85h-6V43.42a1 1 0 0 0-.28-.7 1 1 0 0 0-.71-.28h-7.74V60.2h-6.31V35H54.21A3.17 3.17 0 0 1 51 31.83V6.29H6.32v95.48h37.44v6.31H16.37V116a1 1 0 0 0 .28.7 1 1 0 0 0 .7.28h26.39v5.93H17.39a7 7 0 0 1-6.92-6.91v-7.88H5.66a5.66 5.66 0 0 1-4-1.65 5.6 5.6 0 0 1-1.66-4V5.65A5.65 5.65 0 0 1 5.66 0h48.55a3.09 3.09 0 0 1 2.42 1.16l27.83 27ZM57.35 11.06l18.89 17.63H57.35V11.06ZM20 51.62a2.05 2.05 0 0 0-2 2.14 2 2 0 0 0 2 2.13h37a2.05 2.05 0 0 0 2-2.13 2 2 0 0 0-2-2.14Zm0 15.27A2 2 0 0 0 18.06 69 2 2 0 0 0 20 71.15h23.7v-4.26Zm0 15.26a2 2 0 0 0-1.92 2.13A2 2 0 0 0 20 86.41h23.7v-4.26Zm0-45.79a2 2 0 0 0-2 2.13 2 2 0 0 0 2 2.13h21.89a2 2 0 0 0 1.91-2.13 2 2 0 0 0-1.91-2.13Zm0-15.26a2 2 0 0 0-1.92 2.13A2 2 0 0 0 20 25.36h12a2 2 0 0 0 1.91-2.13A2 2 0 0 0 32 21.1Zm64.73 83h-6.9c-.68-8.39-2.13-9.59-2.13-18a5.6 5.6 0 1 1 11.19 0c0 8.37-1.46 9.58-2.16 18Zm-6.9 3.13h6.91v4.84h-6.93v-4.84Z" style="fill-rule:evenodd" />
            </svg>

            <div>
              <p>Oeps! Er is iets fout gegaan...</p>

              <p>Probeer het later nog eens.</p>
            </div>
          </div>
        </div>`;
    }
  }

  class Form {
    inputs = [];

    constructor(htmlElement) {
      this.formElement = htmlElement;
      this.loader = new LoaderModal(htmlElement);
      this.success = new SuccessModal(htmlElement);
      this.failure = new FailureModal(htmlElement);

      this.initFormInputs();
      this.initSubmitButtons();
    }

    initFormInputs() {
      const inputElements = this.formElement.querySelectorAll('input,textarea');

      inputElements.forEach((element) => {
        const {nodeName, type} = element;

        switch(nodeName) {
          case 'INPUT':
            switch(type) {
              case 'email':
                this.inputs.push(new EmailFormInput(element));
                return;
              case 'text':
              default:
                this.inputs.push(new FormInput(element));
                return;
              }
          case 'TEXTAREA':
            this.inputs.push(new FormInput(element));
            return;
          default:
            throw new Error('NodeName does not match.');
        };
      });
    }

    initSubmitButtons() {
      const submitElements = this.formElement.querySelectorAll('[type="submit"]');

      submitElements.forEach((element) => {
        new SubmitButton(element).addClickEvent(this.validateForm.bind(this));
      });
    }

    /**
     * Send all values to the form.
     *
     * @returns {Promise<void>}
     */
    submit() {
      return new Promise((resolve, reject) => {
        this.startLoading();
        const values = {};

         this.inputs.forEach((input) => {
          let value = input.input.value;

          if (input.type === 'checkbox') {
            value = input.input.checked;
          }

          values[input.input.name] = value;
        });
        console.log('values: ', values);

        postData(`${window.location.origin}/sendEmail.php`, values).then((response) => {
          const {status, message} = response;

          console.log('response: ', response);

          if (status === 'success') {
            resolve();
          }

          reject(message);
        });
      }).then(() => {
        console.log('then');
        this.success.show();

        setTimeout(() => {
          this.success.hide();
          this.stopLoading();
        }, 5000);
      }).catch((message) => {
        console.log('catch: ', message);
        this.failure.show(message);

        setTimeout(() => {
          this.failure.hide();
          this.stopLoading();
        }, 5000);
      });
    }

    startLoading() {
      this.formElement.classList.add('contact__form--loading');
      this.loader.show();
    }

    stopLoading() {
      this.loader.hide();
      this.formElement.classList.remove('contact__form--loading');
    }

    validateForm() {
      let error = false;
      this.inputs.forEach((input) => {
        input.validate(input.input.value);

        if (!input.isValid && input.required) {
          if (!input.error.hasError()) {
            input.showError(input.error.INPUT_REQUIRED);
          }
          error = true;
        }
      });

      if (error) {
        return;
      }

      this.submit();
    }
  }

	const init = () => {
    new Form(document.querySelector('form.contact__form'));
	};

	init();
};

export default formValidation;
