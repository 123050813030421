const scrollOnInit = function() {
  const MINIMUM_HEIGHT = 500;

  // Check if the page
  if (document.body.scrollTop > MINIMUM_HEIGHT || document.documentElement.scrollTop > MINIMUM_HEIGHT) {
    return;
  }

  // Add animation
  document.body.classList.add('animation');

  // Scroll to the top of the page.
  setTimeout(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, 200);

  // Remove animation after 10 seconds
  setTimeout(() => {
    document.body.classList.remove('animation');
  }, 10000);
}

export default scrollOnInit;